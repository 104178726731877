import Vue from "vue";
import store from "@/store";
import axios from "axios";
import { url } from "../../../utils";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: url, //+ "/api",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
});
axiosIns.interceptors.response.use(
  function(response) {
    if (response.config.method != "get") {
      // console.log(re);
      if (response.status == 200) {
        store.commit("UPDATE_SNACKBAR_MESSAGE", {
          message: "تمت العملية بنجاح",
          color: "success",
        });
        store.commit("UPDATE_SNACKBAR", true);
      } else {
        console.log(response);
      }
    }
    return response;
  },
  function(error) {
    store.commit("UPDATE_SNACKBAR", true);
    store.commit("UPDATE_SNACKBAR_MESSAGE", {
      message: error.response.data.error.message,
    });
    // console.log();
    store.commit("setLoading", false);
    return Promise.reject(error);
  }
);
Vue.prototype.$http = axiosIns;
export default axiosIns;
