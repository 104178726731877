import CryptoJS from "crypto-js";
import Vue from "vue";
import VueRouter from "vue-router";
// import { isElectron } from '../../utils'
// import { isUserLoggedIn } from "@/libs/auth/utils";
Vue.use(VueRouter);

function encrypteData(data) {
  let key = CryptoJS.enc.Utf8.parse("edariworkmenkmen");
  const data_encrypted = CryptoJS.AES.decrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  //const iv = CryptoJS.enc.Utf8.parse("keyValue"); // Convert string to WordArray
  //const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });
  return data_encrypted; //" encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}
const routes = [
  //{ path: "/http://localhost:5000/request/", redirect: { name: "test" } },
  /*{
    path: "/home",
    name: "dashboard-home",
    component: () => import("@/views/dashoard/Home.vue"),
    meta: {
      title: "الصفحة الرئيسية",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/settings.vue"),
    meta: {
      title: "المجموعات",
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/categories/Categories.vue"),
    meta: {
      title: "المجموعات",
    },
  },*/
  /*{
    path: "/products",
    name: "products",
    component: () => import("@/views/products/Products.vue"),
    meta: {
      title: "المنتجات",
    },
  },*/
  {
    // path: "/visits",
    path: "/",
    name: "visits",
    component: () => import("@/views/visits/Visits.vue"),
    meta: {
      title: "الزيارات",
    },
  },
  {
    path: "/ ",
    //path: "/",
    name: "salesmen",
    component: () => import("@/views/visits/Salesmen.vue"),
    meta: {
      title: "زيارات المبيعات",
    },
  },
  /*{
    path: "/designs",
    name: "designs",
    component: () => import("@/views/designs/designs.vue"),
    meta: {
      title: "التصاميم",
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/orders/orders.vue"),
    meta: {
      title: "الطلبات",
    },
  },
  {
    path: "/sounds",
    name: "sounds",
    component: () => import("@/views/sounds/sounds.vue"),
    meta: {
      title: "الاصوات",
    },
  },
  {
    path: "/areas",
    name: "areas",
    component: () => import("@/views/areas/areas.vue"),
    meta: {
      title: "المناطق",
    },
  },*/
  {
    name: "login",
    path: "/",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "تسجيل الدخول",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  //let isLogged = localStorage.getItem("accessToken") !== null;
  //let isLogged = localStorage.getItem("userData") !== null;
  let isLogged = localStorage.getItem("accessTocken");
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  let year = date.getFullYear();
  let dT = `${year}-${month}-${day}`;
  if (isLogged != null) {
    let dT_decrypt = encrypteData(isLogged);
    if (dT == dT_decrypt) isLogged = true;
    else isLogged = false;
  } else isLogged = false;

  if (to.name !== "login" && !isLogged) {
    return next({ name: "login" });
  }
  return next();
});
export default router;
